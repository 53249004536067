<template>
  <b-modal id="modal-bulk-edit" modal-class="p-0" size="sm" centered hide-header hide-footer>
    <b-form @submit.prevent="submitData">
      <h2 class="subtitle roboto bold">Bulk Edit Stock</h2>
      <hr/>
      <div class="row">
        <div class="col-md-3 pt-2">Stock</div>
        <div class="col-md-9">
          <input type="number" :min="formData.stock_relative ? null : 0" step="1"
            class="form-control"
            v-model="$v.formData.stock.$model"
            placeholder="0"
          >
        </div>
        <div class="col-md-3 mt-2">Relative</div>
        <div class="col-md-9 pt-1 d-flex">
          <b-form-checkbox switch size="lg" v-model="$v.formData.stock_relative.$model" @input="formData.stock = 0" />
          <span class="pt-1">{{ formData.stock_relative ? 'Yes' : 'No' }}</span>
        </div>
      </div>
      <hr />
      <div class="text-right">
        <button type="submit" class="btn btn-info ml-2">Save</button>
        <button type="button" class="btn btn-secondary ml-2" @click="$bvModal.hide('modal-bulk-edit')">Cancel</button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import { toaster } from "@/_helpers";
import { required, integer, minValue } from "vuelidate/lib/validators";

export default {
  name: "BulkEdit",
  props: ["ids"],
  data() {
    return {
      formData: {
        product_detail_variance_ids: this.ids ?? [],
        stock: 0,
        stock_relative: true,
      },
    }
  },
  validations: {
    formData: {
      product_detail_variance_ids: { required },
      stock_relative: { required },
      stock: { required, integer, ...(() => this.formData.stock_relative ? {} : { minValue: minValue(0) }) },
    },
  },
  methods: {
    async submitData() {
      this.$v.$touch()
      if (this.$v.$error) {
        toaster.make('Please fill in the form correctly', 'danger')
        console.error(Object.keys(this.$v.formData).reduce((obj, key) => {
          if (key.indexOf("$") < 0 && this.$v.formData[key].$invalid) 
              obj[key] = this.$v.formData[key]
          return obj
        }, {}))
        return
      }
      this.$emit('input', this.formData)
      this.$bvModal.hide('modal-bulk-edit')
    },
  },
  watch: {
    ids(newVal) {
      this.formData.product_detail_variance_ids = newVal
    }
  }
}
</script>
