<template>
  <div class="container-fluid">
    <MobileGuide />
    <ProductVarianceNavTab />
    <div class="card p-2">
      <div class="row p-1 align-items-center pb-5">
        <div class="col-md-6">
          <h2 class="roboto subtitle bold">Manage Product Variance</h2>
        </div>
      </div>
      <MyTable
        :fields="table.fields"
        :items="table.items"
        :busy="table.isLoading"
        :selected="table.selected"
        :selectable="table.selectable"
        :sortBy="table.sortBy"
        :sortDesc="table.sortDesc"
        :currentPage="table.currentPage"
        :perPage="table.perPage"
        :totalRows="table.totalRows"
        @onSelected="table.selected = $event"
        @onSortBy="table.sortBy = $event"
        @onSortDesc="table.sortDesc = $event"
        @onChangeCurrentPage="table.currentPage = $event"
        @onChangePerPage="table.perPage = $event"
      >
        <template #header>
          <div class="row">
            <div class="col">
              <b-dropdown v-if="table.selected.length" text="Actions" variant="success">
                <b-dropdown-item v-b-modal.modal-bulk-edit>Edit Selected</b-dropdown-item>
                <b-dropdown-item v-b-modal.modal-delete-data-list>Delete Selected</b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="col text-right">
              <button type="button" class="btn btn-warning" @click="selectedData = null" v-b-modal.modal-save-variant><fawesome-pro variant="fal" icon="plus" /> New Variant</button>
            </div>
          </div>
        </template>
        <template #actions="{ item }">
          <fawesome-pro variant="far" icon="edit" class="pr-1" @click="selectedData = item" v-b-modal.modal-save-variant />
          <fawesome-pro variant="far" icon="trash" class="pr-1" @click="selectedData = item" v-b-modal.modal-delete-data />
        </template>
      </MyTable>
      <div class="d-flex">
        <router-link class="btn btn-info ml-auto" to="/manage-product">Go Back</router-link>
      </div>
    </div>
    <ModalSaveVariance :variant="selectedData" @success="fetchData(itemId)" />
    <ModalBulkEdit :ids="table.selected.map(el => el.id)" @input="bulkEditVariant" />
    <b-modal
      id="modal-delete-data"
      hide-header
      centered
      @ok="deleteVariant([selectedData.id])"
    >
      <p class="text-center m-0">
        <b v-if="selectedData">
          {{ selectedData.product_name }} - {{ selectedData.color_name }} ({{ selectedData.size_name }})
          <br/>
        </b>
        Are you sure you want to delete this variant?
      </p>
    </b-modal>
    <b-modal
      id="modal-delete-data-list"
      hide-header
      centered
      @ok="deleteVariant(table.selected.map(el => el.id))"
    >
      <p class="text-center m-0">
        <b v-for="(selected, i) in table.selected" :key="i">
          {{ selected.product_name }} - {{ selected.color_name }} ({{ selected.size_name }})
          <br/>
        </b>
        Are you sure you want to delete {{ table.selected.length > 1 ? 'these variants' : 'this variant' }}?
      </p>
    </b-modal>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import MyTable from "@/components/shared/MyTable";
import ProductVarianceNavTab from "@/components/productVariance/ProductVarianceNavTab";
import ModalSaveVariance from "@/components/productVariance/ModalSaveVariance";
import ModalBulkEdit from "@/components/productVariance/ModalBulkEditVariance";
import MobileGuide from "@/components/navs/MobileGuide";

export default {
  name: "ProductVariance",
  components: {
    MobileGuide,
    MyTable,
    ProductVarianceNavTab,
    ModalSaveVariance,
    ModalBulkEdit
  },
  data() {
    return {
      selectedData: null,
      table: {
        currentPage: 1,
        perPage: 0,
        totalRows: 0,
        fields: [
          { key: "selected" },
          { key: "size_international", label: "Size" },
          { key: "color_name", label: "Color" },
          { key: "total_stock", label: "Stock" },
          { key: "actions", custom_column: true },
        ],
        items: [],
        selected: [],
        selectable: true,
        // sortBy: "id",
        // sortDesc: false,
        isLoading: false,
      },
    }
  },
  computed: {
    itemId() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.fetchData(this.itemId)
  },
  methods: {
    async fetchData(itemId) {
      this.table.isLoading = true
      try {
        const res = await this.$api.product.getProductVariance(itemId)
        if (res.status === 200) {
          this.table.items = res.data.data
          this.table.totalRows = res.data.meta.total
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        console.error(e)
      }
      this.table.isLoading = false
    },
    async bulkEditVariant(data) {
      try {
        const ids = data.product_detail_variance_ids,
          stock = data.stock,
          relative = data.stock_relative,
          res = await this.$api.product.bulkEditStock(ids, stock, relative)
        if (res.status === 200) {
          this.fetchData(this.itemId)
          toaster.make(res.data.message, 'success')
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    async deleteVariant(itemId) {
      try {
        await Promise.all(itemId.map(id => this.$api.product.deleteVariant(id)))
        this.fetchData(this.itemId)
        toaster.make('Success', 'success')
      } catch (e) {
        console.error(e);
      }
    }
  }
}
</script>
