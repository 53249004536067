<template>
  <div class="mb-5 text-nowrap border-bottom overflow-auto">
    <router-link :to="'/manage-product-variance/' + productId" class="btn btn-wz-tab">Manage Product Variance</router-link>
  </div>
</template>
<script>
export default {
  name: "ProductVarianceNavTab",
  computed: {
    productId() {
      return this.$route.params.id
    }
  }
}
</script>
