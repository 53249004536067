<template>
  <b-modal id="modal-save-variant" modal-class="p-0" size="sm" centered hide-header hide-footer>
    <b-form @submit.prevent="submitData">
      <h2 class="subtitle roboto bold">{{model ? 'Update product variant' : 'Add new variant'}}</h2>
      <hr/>
      <!-- Color -->
      <div class="row mb-2">
        <div class="col-md-3 pt-2">Color</div>
        <div class="col">
          <InputSelect
            v-model="$v.formData.color_id.$model"
            :disabled="model"
            :options="colors"
            placeholder="Select Color"
          />
        </div>
      </div>
      <!-- Size -->
      <div class="row mb-2">
        <div class="col-md-3 pt-2">Size</div>
        <div class="col">
          <InputSelect
            v-model="$v.formData.size_id.$model"
            :disabled="model"
            :options="sizes"
            placeholder="Select Size"
          />
        </div>
      </div>
      <!-- Stock -->
      <div class="row mb-2">
        <div class="col-md-3 pt-2">Stock</div>
        <div class="col">
          <InputText
            type="number"
            v-model="$v.formData.total_stock.$model"
            placeholder="Current Product Stock"
          />
        </div>
      </div>
      <hr />
      <div class="text-right">
        <button type="submit" class="btn btn-info ml-2">{{model ? 'Update' : 'Add'}}</button>
        <button type="button" class="btn btn-secondary ml-2" @click="$bvModal.hide('modal-save-variant')">Cancel</button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import { toaster } from "@/_helpers";
import InputText from "@/components/formCustom/InputText"
import InputSelect from "@/components/formCustom/InputSelect"

import { required, integer, minValue } from "vuelidate/lib/validators";

export default {
  name: "SaveVariance",
  components: {
    InputText,
    InputSelect,
  },
  props: ["variant"],
  data() {
    return {
      model: this.variant,
      formData: {
        color_id: 0,
        size_id: 0,
        total_stock: 0,
      },
      colors: [],
      sizes: [],
    }
  },
  validations: {
    formData: {
      color_id: { required, integer, minValue: minValue(1) },
      size_id: { required, integer, minValue: minValue(1) },
      total_stock: { required, integer, minValue: minValue(0) },
    },
  },
  mounted() {
    this.getColors()
    this.getSizes()
  },
  methods: {
    setData(data) {
      this.formData = {
        color_id: data?.color_id ?? 0,
        size_id: data?.size_id ?? 0,
        total_stock: data?.total_stock ?? 0,
      }
    },
    async submitData() {
      this.$v.$touch()
      if (this.$v.$error) {
        toaster.make('Please fill in the form correctly', 'danger')
        console.error(Object.keys(this.$v.formData).map(el => {
          if (el.indexOf("$") < 0 && this.$v.formData[el].$invalid) 
            return {
              [el]: this.$v.formData[el]
            }
        }).filter(el => el))
      } else {
        try {
          const data = this.formData
          if (this.model) data.id = this.model.id
          data.product_id = this.$route.params.id
          const res = await this.$api.product.saveVariant(data)
          if (res.status === 200) {
            toaster.make(res.data.message, 'success')
            this.$bvModal.hide('modal-save-variant')
            this.$emit('success')
          }
          else toaster.make(res.data.message, 'danger')
        } catch (e) {
          console.error(e);
        }
      }
    },
    async getColors() {
      try {
        const res = await this.$api.cms.getColors()
        if (res.status === 200) this.colors = res.data.data
      } catch (e) {
        console.error(e);
      }
    },
    async getSizes() {
      try {
        const res = await this.$api.cms.getSizes()
        if (res.status === 200) this.sizes = res.data.data.map(el => ({
          id: el.id,
          name: el.international,
        }))
      } catch (e) {
        console.error(e);
      }
    },
  },
  watch: {
    variant(newVal) {
      this.model = newVal
      this.setData(newVal)
    }
  }
}
</script>
